import 'react';
import { categoryIds, productSkuIds } from '../config';
import { KonakartService } from '../services/konakart';
import { useCouponStore } from '../store/couponStore';
import { CouponTypeValue, T_OrderProduct, T_Product } from '../types';
import { useProductInCart } from './useProductInCart';
import { useProductWithCouponRemoval } from './useProductWithCouponRemoval';
import { useRemoveProduct } from './useRemoveProduct';

function useOrderSummary(monthlyFeeCategories: any[], konakartService: KonakartService) {
  const { productsInCart, orderTotals } = useProductInCart();
  const { coupons } = useCouponStore();
  const { handleRemovalOfT_OrderProductWithToast } = useProductWithCouponRemoval(konakartService);
  const { toggleCartProduct, canRemoveFromCart } = useRemoveProduct(konakartService, CouponTypeValue.Upgrade);

  const oneOffFeeCategories = [categoryIds.broadbandTechnicianFee];

  const monthlyFees = productsInCart?.filter(p => monthlyFeeCategories.find(pc => pc === p.categoryId));

  const productsWithoutHindiChannels = productsInCart?.filter(
    p => monthlyFeeCategories.find(pc => pc === p.categoryId) && p.categoryId !== categoryIds.hindiChannels
  );
  const productsHindiChannels = productsInCart?.filter(
    p => monthlyFeeCategories.find(pc => pc === p.categoryId) && p.categoryId === categoryIds.hindiChannels
  );

  const monthlyFeesTotal = orderTotals?.find(ot => ot.className === 'ot_subtotal')?.value ?? 0;
  const firstMonthCharge = orderTotals?.find(ot => ot.className === 'ot_total')?.value ?? 0;
  const ot_total_data = orderTotals?.find(ot => ot.className === 'ot_total_data')?.value ?? 0;

  // Free product, assumes there's only one free product (Soho)
  const freeProductId = orderTotals?.find(ot => ot.className === 'ot_free_product')?.freeProductId;
  const freeProduct = productsInCart?.find(p => p.productId === freeProductId);
  const freeProductPromotion = productsInCart?.find(p => p.productId === freeProductId)?.promotionTitle;

  // One-off fees
  const oneOffFees = productsInCart?.filter(p => oneOffFeeCategories.find(pc => pc === p.categoryId));

  /* Total payable for the 1st month already includes one off charges (Handled in KK-service). */
  const total = firstMonthCharge + ot_total_data;

  const handleRemove = (product: T_OrderProduct) => {
    return async () => {
      await handleRemovalOfT_OrderProductWithToast(
        { name: product.product.name, id: product.productId },
        toggleCartProduct,
        true
      );
    };
  };

  const isNotBroadband = (product: T_Product) => {
    return !(product.categoryId === categoryIds.broadband);
  };

  const isNotFreeProduct = (product: T_Product) => {
    return !(product.id === freeProduct?.productId);
  };

  const canRemoveProduct = (product: T_OrderProduct) => {
    const remove =
      (product.product.sku === productSkuIds.broadbandStaticIP.primary || canRemoveFromCart(product.product)) &&
      isNotBroadband(product.product) &&
      isNotFreeProduct(product.product);
    return remove;
  };

  // do nto count free soho as offer
  const hasOffer = coupons.length > 0;

  const getHeader = () => {
    let header = productsInCart
      ?.filter(p => p.categoryId === categoryIds.broadband)
      .slice(0, 1)
      .shift()?.product.name;
    if (productsInCart?.find(p => p.categoryId === categoryIds.package)) {
      header = header + ' with Sky TV';
    }
    return header;
  };

  const getHindiChannelsPromoPrice = () => {
    if (productsHindiChannels && productsHindiChannels?.length <= 1) {
      return '$9.99';
    } else if (productsHindiChannels?.length === 2) {
      return '$14.99';
    } else {
      return '$19.99';
    }
  };

  return {
    freeProduct,
    freeProductPromotion,
    productsInCart,
    monthlyFees,
    productsWithoutHindiChannels,
    productsHindiChannels,
    oneOffFees,
    monthlyCharge: monthlyFeesTotal,
    coupons,
    header: getHeader(),
    firstMonthCharge,
    total,
    hasOffer,
    handleRemove,
    canRemoveProduct,
    hindiChannelsPromoPrice: getHindiChannelsPromoPrice(),
  };
}

export { useOrderSummary };
