import React from 'react';
import { Button, TwoOptionsSelector, OneMonthOnUs, UpcomingOffer } from '@sky-tv-group/components';
import { useProductStore, segment, useAnalytics } from '@sky-tv-group/shared';
import { SkyCustomerYesNoOption, YesNoValueType } from '../../types';
import { useStore } from '../../store';
import { SPLITIO_KEY, useSplitIO } from '@sky-tv-group/shared';
import {  myAccountV2SWR } from '../../services';
import { BUNDLE_OFFER_BANNER } from '../../config';
import { GetSkyOfferBanner } from '../../components/getskyofferbanner';

interface StarterSelectorProps {
  isLoadingProduct: boolean;
  isActiveSkyStarter: boolean | undefined;
  onSelectChange: (value: boolean) => void;
  broadbandFilter: 'BROADBAND_ONLY' | 'BROADBAND_WITH_SKY_TV';
  setBroadbandFilter: (val: 'BROADBAND_ONLY' | 'BROADBAND_WITH_SKY_TV') => void;
}

const StarterSelector = ({
  isLoadingProduct,
  onSelectChange,
  broadbandFilter,
  setBroadbandFilter,
  isActiveSkyStarter,
}: StarterSelectorProps) => {
  const sectionName = BUNDLE_OFFER_BANNER;
  const [show25offer] = useSplitIO(SPLITIO_KEY.SKYWEB_25_OFF_CAMPAIGN);
  const [showBundleOfferBanner] = useSplitIO(SPLITIO_KEY.SKYWEB_BUNDLE_OFFER_BANNER);
  const { toggleClicked } = useAnalytics();

  const { selectedIsSkyCustomer, setSelectedIsSkyStarterCustomer } = useStore(s => ({
    selectedIsSkyCustomer: s.selectedIsSkyStarterCustomer,
    setSelectedIsSkyStarterCustomer: s.setSelectedIsSkyStarterCustomer,
  }));

  const { isCartTypeUpgrade } = useProductStore();

  const { setProductStoreCartTypeUpgrade } = useProductStore(s =>
    // Selecting parts of store to bind to root component
    ({
      setProductStoreCartTypeUpgrade: s.setProductStoreCartTypeUpgrade,
    })
  );

  const setSkyCustomerFlag = (value: YesNoValueType) => {
    setSelectedIsSkyStarterCustomer(value === SkyCustomerYesNoOption.YES);
    setProductStoreCartTypeUpgrade(value === SkyCustomerYesNoOption.YES);
    onSelectChange(value === SkyCustomerYesNoOption.YES);
  };

  // const { data: bannerData, isValidating } = myAccountV2SWR.useGetComponentCardSectionGQL(false, sectionName);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row">
        <div className="md:mr-auto">
          <div className="sky-h4-black md:sky-h5-black mb-4">
            2. Choose an <span className="text-bborange">UNLIMITED</span> fibre plan
          </div>
          <div>
            <div className="flex flex-col md:flex-row md:items-center md:justify-center">
              <div className="sky-h6-reg">Are you a Sky Customer with a Sky Box in your home?</div>
              <TwoOptionsSelector
                valueSelected={selectedIsSkyCustomer ? SkyCustomerYesNoOption.YES : SkyCustomerYesNoOption.NO}
                valueLeft={SkyCustomerYesNoOption.YES}
                valueRight={SkyCustomerYesNoOption.NO}
                onSelectChange={setSkyCustomerFlag}
                isDisabled={isLoadingProduct}
                dataTestId="Starter"
              />
            </div>
          </div>
        </div>
        {!isActiveSkyStarter && (
          <div className="flex md:flex-row justify-center pt-4 md-pt-0 md:items-end">
            <Button
              onClick={() => {
                setBroadbandFilter('BROADBAND_ONLY');
                segment.toggleClicked('offers | Broadband', "Broadband only", 'Checkbox')
              }}
              className="mr-2"
              style={{ maxWidth: 250 }}
              colorMode={broadbandFilter === 'BROADBAND_ONLY' ? 'pure' : 'light'}
              variant={broadbandFilter === 'BROADBAND_ONLY' ? 'primary' : 'secondary'}>
              Broadband only
            </Button>
            <Button
              onClick={() => {
                setBroadbandFilter('BROADBAND_WITH_SKY_TV');
                segment.toggleClicked('offers | Broadband', "Broadband with Sky TV", 'Checkbox')
              }}
              style={{ maxWidth: 250 }}
              colorMode={broadbandFilter === 'BROADBAND_WITH_SKY_TV' ? 'pure' : 'light'}
              variant={broadbandFilter === 'BROADBAND_WITH_SKY_TV' ? 'primary' : 'secondary'}>
              Broadband with Sky TV
            </Button>
          </div>
        )}
      </div>

      {
        broadbandFilter === 'BROADBAND_WITH_SKY_TV' &&  (
          <div className="flex justify-center items-center mr-50 md:mx-8 mt-12">
            <h4 className="header1 sky-h2 font-black">
              {'Our recommended bundles with Sky TV.'}
            </h4>
        </div>

        )
      // broadbandFilter === 'BROADBAND_ONLY' ? (
      //   <div className="flex justify-center items-center mr-10 md:mx-8">
      //     <UpcomingOffer
      //       className="mt-8 w-full md:mt-6"
      //       bgColor={'#00013A'}
      //       isUpgradeJourney={true}
      //       text={!selectedIsSkyCustomer ? '12 month contract, exit fees and ' : ''}
      //     />
      //   </div>
      // ) : (
        // <div className="flex justify-center items-center mr-10 md:mx-8">
        //   { show25offer && (<UpcomingOffer className="mt-8 w-full md:mt-6" bgColor={'#fff'} isUpgradeJourney={false} />)}
        //   {/* { !showBundleOfferBanner && bannerData && (<div style={{ marginTop: '20px'}}><GetSkyOfferBanner bannerData={bannerData} />
        //   </div>)} */}
        // </div>
      // )
       }

    </div>
  );
};

export { StarterSelector };
