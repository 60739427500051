import { AxiosInstance } from 'axios';
import { format, parseISO } from 'date-fns';
import {
  OrderResponse,
  CustomerDetails,
  OrderDetails,
  T_Order,
  WorkOrderStatus,
  LocationAddresses,
  T_Customer,
  T_OrderTotal,
  T_OrderProduct,
  BillingCampaign,
  T_FaultSwapReplacementResponse,
  abandonedCartResponse,
  Box,
  T_Coupon,
  YourDetails,
  PropertyDetail,
} from '../types';
import { isNonNil } from '../helpers/filter';

export class OrderService {
  private orderAgent: AxiosInstance;

  constructor(orderAgent: AxiosInstance) {
    this.orderAgent = orderAgent;
  }

  // workOrderClassDescription from technician endpoint.
  broadbandOrderType = 'BROADBAND SERVICES';

  // check to see how this is called,
  // make a get call for new service
  public postNewOrder = async (
    order: T_Order,
    customerDetails: CustomerDetails,
    orderDetails: OrderDetails,
    captchaToken: string,
    useNewOrderEndpoint = false,
    eligibilityId: string | null = null
  ) => {
    let payload: any = {
      order,
      customerDetails: customerDetails,
      orderDetails: orderDetails,
      captchaToken: captchaToken,
      ...(eligibilityId ? { eligibilityId } : {}),
      useNewOrderEndpoint,
    };

    return (await this.orderAgent.post<OrderResponse>('/cart/newOrder', payload)).data;
  };

  public postUpgradeOrder = async (
    orderTotals: T_OrderTotal[],
    orderAddedProduct: T_OrderProduct[],
    customer: T_Customer,
    orderDetails: OrderDetails,
    existingCampaigns: BillingCampaign[]
  ) => {
    const primaryDetails = customer.Parties.find(p => p.id === '1')?.types['MP:Primary'] || undefined;
    const dateOfBirth = primaryDetails?.personalDetails.dob
      ? format(parseISO(primaryDetails?.personalDetails.dob), 'yyyy-MM-dd')
      : null;

    const phoneNumber = primaryDetails?.contactDetails?.[0];
    let req = {
      accountNumber: customer.accountNumber,
      houseNumber: customer.houseNumber,
      externalAccountId: customer.externalAccountId,
      customerDetails: {
        email: customer.serviceContactEmail,
        dateOfBirth: dateOfBirth,
        firstName: primaryDetails?.personalDetails.firstName,
        lastName: primaryDetails?.personalDetails.lastName,
        phoneNumbers: [
          {
            type: 'Primary',
            phoneNumber: {
              areaCode: phoneNumber?.areaCode,
              number: phoneNumber?.number,
            },
          },
        ],
      },
      orderDetails: orderDetails,
      orderTotals,
      orderAddedProduct,
      existingCampaigns,
    };

    return (await this.orderAgent.post<OrderResponse>('/cart/upgradeOrder', req)).data;
  };

  public postUpgradeOrderProcessor = async (
    orderTotals: T_OrderTotal[],
    orderAddedProduct: T_OrderProduct[],
    customer: T_Customer,
    orderDetails: OrderDetails,
    existingCampaigns: BillingCampaign[],
    customerTransfer?: boolean,
    eligibilityID?: string | null
  ) => {
    const primaryDetails = customer.Parties.find(p => p.id === '1')?.types['MP:Primary'] || undefined;
    const dateOfBirth = primaryDetails?.personalDetails.dob
      ? format(parseISO(primaryDetails?.personalDetails.dob), 'yyyy-MM-dd')
      : null;

    const phoneNumber = primaryDetails?.contactDetails?.[0];
    let payload: any = {
      accountNumber: customer.accountNumber,
      houseNumber: customer.houseNumber,
      externalAccountId: customer.externalAccountId,
      ...(eligibilityID ? { eligibilityID } : {}),
      ...(customerTransfer ? { transfer: true, migrationType: 'Dart' } : {}),
      customerDetails: {
        email: customer.serviceContactEmail,
        dateOfBirth: dateOfBirth,
        firstName: primaryDetails?.personalDetails.firstName,
        lastName: primaryDetails?.personalDetails.lastName,
        phoneNumbers: [
          {
            type: 'Primary',
            phoneNumber: {
              areaCode: phoneNumber?.areaCode,
              number: phoneNumber?.number,
            },
          },
        ],
      },
      orderDetails: orderDetails,
      orderTotals,
      orderAddedProduct,
      existingCampaigns,
    };

    return (await this.orderAgent.post<OrderResponse>('/cart/upgradeOrderProcessor', payload)).data;
  };

  // fault swap

  public postFaultSwapReplacement = async (customer: T_Customer, faultswapremotepayload: any) => {
    const primaryDetails =
      customer?.Parties?.find(p => p.id === '1')?.types['MP:Primary'] ??
      customer?.Parties?.find(p => p.id === '2')?.types['BT:Billing'];
    const customerName = [
      primaryDetails?.personalDetails?.firstName ?? '',
      primaryDetails?.personalDetails?.lastName ?? '',
    ]
      .filter(isNonNil)
      .join(' ');
    let payload: any = {
      customerName,
      accountNo: customer.accountNumber,
      ...faultswapremotepayload,
    };

    return (await this.orderAgent.post<T_FaultSwapReplacementResponse>('/cart/faultSwapOrderProcessor', payload))?.data;
  };

  public postOneClickPurchaseOrderProcessor = async (payload: any) =>
    (await this.orderAgent.post<OrderResponse>('/cart/oneClickPurchaseOrderProcessor', payload))?.data;

  public getOrderStatus = async (accountNumber: string, withDetails: boolean = false) =>
    (
      await this.orderAgent.get<WorkOrderStatus[] | undefined>(
        `/technician?accountNumber=${accountNumber}&withDetails=${withDetails}`
      )
    ).data;

  public getBroadbandWorkOrders = async (accountNumber: string, withDetails: boolean) => {
    const orderData = await this.getOrderStatus(accountNumber, withDetails);
    return orderData?.filter(x => x.workOrderClassDescription === this.broadbandOrderType);
  };

  public getLocationIdFromTui = async (tui: string) => {
    let locationData = (await this.orderAgent.get<LocationAddresses>(`/location?tui=${tui}`)).data;
    if (locationData?.addresses?.length > 0) {
      return locationData.addresses[0];
    }
    return null;
  };

  // Method to resend verification email
  public postReSendVerificationMail = async (accountNumber: string, email: string) => {
    let payload: any = {
      accountNumber,
      email,
    };

    return (await this.orderAgent.post('/cart/resendOrderVerificationProcessor', payload))?.data;
  };
  public getAbandonedCartData = async (RefId: string) => {
    let cartDetails = (await this.orderAgent.get<any>(`/cart/getAbandonedCartData`, { params: { RefId } })).data;
    return cartDetails;
  };
  public postAbandonedCart = async (
    email: string,
    orderDetails: T_Order | undefined,
    boxDetails: Box[],
    couponDetails?: T_Coupon[],
    customerDetails?: YourDetails,
    propertyDetails?: PropertyDetail,
    //addressId?:AddressData,
    addressData?: any,
    jounery?: string
  ) => {
    let payload: any = {
      email: email ? email : '',
      orderDetails: orderDetails,
      boxDetails: boxDetails ? boxDetails : [],
      couponDetails: couponDetails ? couponDetails : [],
      customerDetails: customerDetails ? customerDetails : {},
      propertyDetails: propertyDetails ? propertyDetails : {},
      addressData: addressData ? addressData : {},
      jounery: jounery,
    };

    return (await this.orderAgent.post<abandonedCartResponse>('/cart/abandonedCart', payload)).data;
  };

  public postReschedule = async (
    accountNo: string,
    workOrderNumber: string,
    houseNumber: string,
    timeSlotCode: string,
    startTime: string,
    comments: string
  ) => {
    let payload = {
      accountNo: accountNo,
      workOrderNumber: workOrderNumber,
      houseNumber: houseNumber,
      timeSlotCode: timeSlotCode,
      startTime: startTime,
      comments: comments,
    };

    let response = await this.orderAgent.post('/cart/reschedule', payload);
    return await response.data;
  };

  public postCancelWorkOrder = async (
    accountNo: string,
    workOrderNumber: string,
    houseNumber: string,
    reasonCode: string,
    comments: string
  ) => {
    let payload = {
      accountNo: accountNo,
      workOrderNumber: workOrderNumber,
      houseNumber: houseNumber,
      reasonCode: reasonCode,
      comments: comments,
    };
    let response = await this.orderAgent.post('/cart/cancel', payload);
    return await response.data;
  };

  public postTroubleCall = async (
    accountNo: string,
    address: string,
    tuiid: string,
    houseNumber: string,
    timeSlotCode: string,
    startTime: string, // Consider using Date if you will parse it
    problemCode: string,
    comments: string,
    city: string,
    postalCode: string,
    callCode: string,
    stageCode: string,
    alternativeContactNum: string,
    alternativeContactName: string,
    isAnimalOnSite: string, // Retaining the hyphenated key
    isAdult: string // Assuming 'y' or 'n' for boolean values
  ) => {
    let payload = {
      accountNo: accountNo,
      address: address,
      tuiid: tuiid,
      houseNumber: houseNumber,
      timeSlotCode: timeSlotCode,
      startTime: startTime,
      problemCode: problemCode,
      comments: comments,
      city: city,
      postalCode: postalCode,
      callCode: callCode,
      stageCode: stageCode,
      alternativeContactNum: alternativeContactNum,
      alternativeContactName: alternativeContactName,
      isAnimalOnsite: isAnimalOnSite,
      isAdult: isAdult,
    };
    let response = await this.orderAgent.post('/cart/trouble-call', payload);
    return await response.data;
  };
}
