import React, { useEffect } from 'react';

import {
  formatMoney,
  SPLITIO_KEY,
  useSplitIO,
  useOrderSummary,
  categoryIds,
  useRunningTotal,
  useCoupon,
  useCartContainer,
  CouponTypeValue,
} from '@sky-tv-group/shared';
import { MainProduct, RunningTotalOffer } from '@sky-tv-group/components';

import history from '../../history';
import { ContinueButtonArea } from '../../components/continue';
import { AppRoutes } from '../../constants';
import { kkService } from '../../services';

const BlueSubText = ({ text }: { text: string }) => (
  <p className="sky-text-daylight pb-2 text-blue-light sky-h7-reg">{text}</p>
);

const OrderSummary = () => {
  let { isOneOffProduct } = useRunningTotal(kkService, []);
  const { getOfferEndsText } = useCoupon();
  const { monthlyFees } = useCartContainer(kkService, CouponTypeValue.Acquisition);
  const [allowCheckout] = useSplitIO(SPLITIO_KEY.SKYWEB_BROADBAND_AND_TV_ACQUISTION);

  const monthlyFeeCategories = [
    categoryIds.broadband,
    categoryIds.broadbandDevices,
    categoryIds.package,
    categoryIds.packageUpgrade,
    categoryIds.box,
    categoryIds.special,
    categoryIds.voice,
    categoryIds.voiceAddons,
    categoryIds.voiceCrossCountry,
    categoryIds.additionalCharges,
    categoryIds.hindiChannels,
    categoryIds.multiroom,
  ];

  const {
    freeProduct,
    freeProductPromotion,
    productsInCart,
    productsWithoutHindiChannels,
    productsHindiChannels,
    monthlyCharge,
    oneOffFees,
    coupons,
    header,
    total,
    hasOffer,
    handleRemove,
    canRemoveProduct,
    hindiChannelsPromoPrice,
  } = useOrderSummary(monthlyFeeCategories, kkService);

  useEffect(() => {
    if (!allowCheckout) {
      return history.push(AppRoutes.Home);
    }
  }, [allowCheckout]);

  return (
    <>
      <h2 className="sky-h2 md:sky-h2 text-center py-16 text-white sky-blue-gradient checkout-banner">Order Summary</h2>
      <div className="md:container m-auto md:pb-10 md:pt-10 text-navy">
        <h4 className="bg-gray-light sky-h3 md:sky-h4 text-center h-24 p-4 flex justify-center items-center font-bold">
          Your Plan
        </h4>
        <div className="w-full">
          <div className="flex flex-col md:flex-row py-4">
            <div className="w-full md:w-3/5 my-4">
              <div className="flex-1 mx-4 md:mx-0">
                <div className="flex-col p-4 lg:pl-1 divide-y">
                  <div className="mb-4">
                    <h4 className="flex-1 sky-h6-bold md:sky-h6-bold">{header}</h4>
                    <p className="sky-h7-bold">On a 12 month contract</p>
                  </div>
                  {productsWithoutHindiChannels?.length !== 0 && (
                    <div className="pt-4">
                      {productsWithoutHindiChannels?.map(product => (
                        <MainProduct
                          key={product.productId}
                          product={product}
                          hidePrice={false}
                          isFreeProduct={product.productId === freeProduct?.productId}
                          onRemove={canRemoveProduct(product) ? handleRemove(product) : undefined}
                        />
                      ))}
                      {productsHindiChannels &&
                        productsHindiChannels?.length === 1 &&
                        productsHindiChannels?.map(product => (
                          <MainProduct
                            key={product.productId}
                            product={product}
                            hidePrice={false}
                            isFreeProduct={product.productId === freeProduct?.productId}
                            onRemove={canRemoveProduct(product) ? handleRemove(product) : undefined}
                          />
                        ))}
                    </div>
                  )}
                  {productsHindiChannels && productsHindiChannels?.length > 1 && (
                    <div className="pt-4">
                      <p className="sky-h6-bold pb-1">{productsHindiChannels?.length} Hindi Channels Bundle</p>
                      <div className="flex pb-4">
                        <p className="sky-h6-bold">{hindiChannelsPromoPrice}</p>
                        <p className="sky-h7-reg text-gray-darker">/mth</p>
                      </div>
                      {productsHindiChannels?.map(product => (
                        <MainProduct
                          key={product.productId}
                          product={product}
                          hidePrice={true}
                          isFreeProduct={product.productId === freeProduct?.productId}
                          onRemove={canRemoveProduct(product) ? handleRemove(product) : undefined}
                        />
                      ))}
                    </div>
                  )}
                  <div className="mb-4 pt-4">
                    <h4 className="flex-1 sky-h6-bold md:sky-h6-bold pb-2">Your Monthly Charge</h4>
                    <h4 className="flex-1 sky-h6-bold md:sky-h6-bold">{formatMoney(monthlyCharge)}</h4>
                    {hasOffer && <BlueSubText text="Before offer applied" />}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-2/5 my-4">
              <div className="flex-1 flex-col mx-4 md:mx-0 border-gray-light border h-3/4">
                <div className="flex flex-col">
                  <div className="p-5 text-white bg-blue-light">
                    <h4 className="sky-h6-reg md:sky-h6-bold mt-4 mb-2">Your First Bill Estimate</h4>
                    <p className="sky-h1-reg font-bold">{formatMoney(total)}</p>
                  </div>
                </div>
                <div className="p-4 divide-y">
                  <div className="mb-4 pt-4">
                    <div className="mb-4">
                      <p className="sky-h5-reg pb-1">Your Monthly Charge</p>
                      {hasOffer && <BlueSubText text="Before offer applied" />}
                      <p className="sky-h6-bold pb-1">{formatMoney(monthlyCharge)}</p>
                    </div>
                    {(coupons.length !== 0 || freeProductPromotion) && productsInCart && (
                      <div className="mb-4">
                        <p className="sky-h5-reg text-blue-light pb-1">{`${
                          coupons.length > 1 ? 'Offers' : 'Offer'
                        } Applied`}</p>
                        {coupons?.map((coupon, i) => (
                          <>
                            {/* <OrderSummaryOffer key={coupon.id} coupon={coupon} addedProducts={productsInCart} /> */}
                            <RunningTotalOffer
                              key={`${coupon.id}_${i}`}
                              coupon={coupon}
                              offer={undefined}
                              offerText={
                                !isOneOffProduct(coupon)
                                  ? getOfferEndsText(coupon, monthlyFees, null, true)
                                  : coupon.description
                              }
                            />
                          </>
                        ))}
                        {freeProductPromotion && freeProduct && (
                          <div className="mt-4">
                            <h4 className="sky-h6-bold pb-1">{`Free ${freeProduct.product.name}`}</h4>
                            <p className="sky-h7-reg text-gray-darker">{freeProductPromotion}</p>
                          </div>
                        )}
                      </div>
                    )}
                    {productsHindiChannels && productsHindiChannels.length > 1 && (
                      <div className="mb-4">
                        <h4 className="sky-h6-bold pb-1">{`Hindi Channels Bundle`}</h4>
                        <p className="sky-h7-reg text-gray-darker">
                          {'Bundle any two Hindi language channels for $14.99/mth or all three for $19.99/mth'}
                        </p>
                      </div>
                    )}
                    {oneOffFees && oneOffFees.length > 0 && (
                      <div className="mb-4">
                        <h4 className="bg-gray-light sky-h3 text-left px-2 py-4 mb-4 font-bold">One-off Charges</h4>
                        {oneOffFees?.map(p => (
                          <div className="my-2">
                            <h4 className="sky-h6">{p.product.name}</h4>
                            <BlueSubText text="Charged after the technician visit" />
                            <p className="sky-h6-bold mt-2">{`+${formatMoney(p.product.priceIncTax)}`}</p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col mb-4 pt-6">
                    <p className="sky-h5-bold w-full pb-1">Total</p>
                    <p className="sky-h4-bold w-full pb-1">{formatMoney(total)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContinueButtonArea />
    </>
  );
};

export { OrderSummary };
