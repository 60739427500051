import React from "react";
import { Button } from "@sky-tv-group/components";

interface BuildYourOwnProps {
  bannerData: any;
}

const BuildYourOwn: React.FC<BuildYourOwnProps> = ({ bannerData }) => {

    const [desktop, setDesktop] = React.useState(false);
    const componentData = bannerData?.data?.ComponentCardSection[0]?.componentCards[0];
    const packageWindowResize = () => {
        if (window.innerWidth > 767) {
            setDesktop(true);
        } else {
            setDesktop(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('resize', packageWindowResize);
        packageWindowResize();
        return () => window.removeEventListener('resize', packageWindowResize);
    }, []);

    return (<div className="p-5 bg-white rounded-lg border-2 border-neutral-400"
        style={{
            display: 'flex',
            flexDirection: desktop ? 'row' : 'column',
            width: desktop ? '60%' : '',
            gap: desktop ? '24px' : '12px',
            marginLeft: desktop ? '20%' : '8px',
            marginRight: desktop ? '20%' : '8px',
            marginBottom: '32px',
        }}
    >
        <img className="relative" src={ componentData?.cardImageUrl?.url} />
        <div className="flex-col justify-start items-start gap-4 inline-flex">
            <div className="text-[28px] font-skyBlack font-black leading-[30.80px]">{componentData.cardTitle}</div>
            <div className="text-base leading-tight">{componentData.cardDescription} </div>
            <Button
                onClick={() => {
                    window.open(componentData.linkUrl, '_blank');
                }}
                variant="secondary"
                colorMode="pure"
            >
                {componentData.linkText}
            </Button>
        </div>
    </div>);
}


export default BuildYourOwn;